var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_master_driver") } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("a-input-search", {
                    staticClass: "input-search",
                    style: { "max-width": "400px", "margin-right": "8px" },
                    attrs: {
                      loading: _vm.loading.find || _vm.loading.download,
                      "enter-button": "",
                      placeholder: _vm.$t("lbl_search_by_driver_code_or_name"),
                      "data-testid": "driver-search"
                    },
                    on: { search: _vm.handleSearch },
                    model: {
                      value: _vm.inputSearch,
                      callback: function($$v) {
                        _vm.inputSearch = $$v
                      },
                      expression: "inputSearch"
                    }
                  }),
                  _c("a-button", {
                    attrs: {
                      icon: "reload",
                      type: "primary",
                      "data-testid": "driver-reset-data"
                    },
                    on: { click: _vm.resetData }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              size: "small",
              dataSource: _vm.masterDrivers.data,
              columns: _vm.masterDriverTableColumns,
              "row-key": "id",
              loading: _vm.loading.find,
              pagination: {
                showTotal: function() {
                  return _vm.$t("lbl_total_items", {
                    total: _vm.masterDrivers.totalElements
                  })
                },
                total: _vm.masterDrivers.totalElements,
                showSizeChanger: true,
                pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                current: _vm.pagination.page,
                defaultPageSize: _vm.pagination.limit
              },
              scroll: { y: 600, x: 1000 },
              "data-testid": "driver-table"
            },
            on: { change: _vm.onChangeTable },
            scopedSlots: _vm._u([
              {
                key: "boolean",
                fn: function(text, record) {
                  return _c(
                    "span",
                    {},
                    [_c("DisplayBoolean", { attrs: { value: record.active } })],
                    1
                  )
                }
              }
            ])
          }),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.find || _vm.loading.download,
                        "data-testid": "driver-download"
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }