import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { TruckingMasterDriverParamDownload } from "@/models/interface/trucking-master-driver";
import { TruckingMasterRateUpdateRequestDto } from "@/models/interface/trucking-master-rate";
import store from "@/store";
import { FormValue } from "@/store/truckingMasterRate.store";

export class TruckingMasterDriverMapper {
  static paginationParamToParamDownload(
    requestQueryParams: RequestQueryParams,
    search: string
  ): TruckingMasterDriverParamDownload {
    const company = store.state["authStore"].authData.companyName || "";
    const filter = search ? search : "ALL";
    const searchParams = [company, filter];

    return {
      ...requestQueryParams,
      params: searchParams.join(","),
    };
  }

  static toMasterRateUpdateDTO(
    form: FormValue
  ): TruckingMasterRateUpdateRequestDto {
    return {
      rateAccountId: form.rateAccount?.key ?? "",
      rateId: form.rateId,
      rateName: form.rateName,
    };
  }
}
